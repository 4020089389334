import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export interface MonitoringParams {
  environment: string;
  version: string;
  host: string; // window.location.host
}

export const initMonitoring = (params: MonitoringParams) => {
  const clientToken = 'pub1833505eb01090c69a287dc468ef8568';
  const service = 'highlighter-app-ui';
  const site = 'datadoghq.com';
  const isLocalEnvironment: boolean = params.host.startsWith('localhost');

  datadogLogs.init({
    clientToken: clientToken,
    site: site,
    service: service,
    forwardErrorsToLogs: true,
    // If you forwardConsoleLogs to 'all', DataDog's consoleObservable.ts:40 is always the source of
    // the log. Using undefined here keeps code navigation for development.
    forwardConsoleLogs: isLocalEnvironment ? undefined : 'all',
    env: params.environment,
    version: params.version,
  });

  // Do not submit logs to DataDog or initialize RUM (Realtime User Monitoring) when on localhost.
  if (isLocalEnvironment) {
    // https://docs.datadoghq.com/logs/log_collection/javascript/#change-the-destination
    datadogLogs.logger.setHandler('console');
  } else {
    datadogRum.init({
      applicationId: '0e3d699b-92ec-4d7b-8a8a-bfd7011ca12f',
      clientToken: clientToken,
      site: site,
      service: service,
      env: params.environment,
      version: params.version,
      sampleRate: 100,
      sessionReplaySampleRate: 0,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
};
